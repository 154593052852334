




































import { Component, Inject, Prop } from 'vue-property-decorator'

import { PickerCallback } from '../../../contexts'

import { AbstractModuleForm } from '../../abstract/form'
import { FormFieldset } from '../../partials/FormFieldset'
import { SetForm } from '../../partials/SetForm'
import { ProductForm } from '../../partials/ProductForm'

import {
  ProductReviewsModule,
  ProductReviewsModuleContent,
  ProductReviewsModuleContentInput
} from '../ProductReviews.contracts'

import { productReviewsContentFactory } from '../ProductReviews.factory'
import { defaultProvider, IS_SMART_MODULES_MODE_KEY } from '../../../support'

/**
 * Form component for the `ProductReviews` module.
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<ProductReviewsModuleForm>({
  name: 'ProductReviewsModuleForm',
  components: { FormFieldset, SetForm, ProductForm }
})
export class ProductReviewsModuleForm extends AbstractModuleForm<ProductReviewsModuleContentInput, ProductReviewsModule> {
  @Inject({
    from: IS_SMART_MODULES_MODE_KEY,
    default: () => defaultProvider<boolean>(false)
  })
  public readonly isSmart!: () => boolean

  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  /**
   * Determines product reviews list initial content.
   */
  public initialContent: ProductReviewsModuleContent = productReviewsContentFactory()

  /**
   * Determines getter for reviews per page.
   */
  public get reviewsPerPage () {
    return this.data.reviewsPerPage
  }

  /**
   * Determines setter for reviews per page.
   */
  public set reviewsPerPage (amount: number) {
    this.data.reviewsPerPage = amount
  }
}
export default ProductReviewsModuleForm
