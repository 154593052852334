






























































import { Component, Prop } from 'vue-property-decorator'

import { PickerCallback, PossibleRelatedPicker, Related } from '../../../contexts'

import { AbstractModuleForm } from '../../abstract/form'
import { ArticleForm } from '../../partials/ArticleForm'
import { FormAddBtn } from '../../partials/FormAddBtn'
import { FormFieldset } from '../../partials/FormFieldset'
import { HeadingForm } from '../../partials/HeadingForm'
import { Iterator } from '../../partials/Iterator'
import { SetForm } from '../../partials/SetForm'
import {
  ArticlesTeaserModule,
  ArticlesTeaserModuleContent,
  ArticlesTeaserModuleContentInput
} from '../ArticlesTeaser.contracts'
import { articlesTeaserContentFactory } from '../ArticlesTeaser.factory'

/**
 * Form component for the `ArticlesTeaser` module.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl> (original)
 */
@Component<ArticlesTeaserModuleForm>({
  name: 'ArticlesTeaserModuleForm',
  components: { FormAddBtn, Iterator, ArticleForm, FormFieldset, HeadingForm, SetForm }
})
export class ArticlesTeaserModuleForm extends AbstractModuleForm<ArticlesTeaserModuleContentInput, ArticlesTeaserModule> {
  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  /**
   * Determines articles teaser initial content.
   */
  public initialContent: ArticlesTeaserModuleContent = articlesTeaserContentFactory()

  /**
   * Determines related picker.
   */
  public picker = PossibleRelatedPicker

  public get isManual (): boolean {
    return !!this.data.article?.length
  }

  public get isSet (): boolean {
    return !!this.data.articles
  }

  public get isEmpty (): boolean {
    return !this.data.articles && !this.data.article?.length
  }

  public addNewItem () {
    return null
  }

  public setIsManual () {
    this.data.article = [null as unknown as Related]
  }
}

export default ArticlesTeaserModuleForm
