

















































































import { Component } from 'vue-property-decorator'
import { SizeMap, DashmixSelectItem } from '@movecloser/ui-core'

import { AbstractModuleForm } from '../../abstract/form'

import { ColorSchemaSelector } from '../../partials/ColorSchemaSelector'
import { HAlignmentSelector } from '../../partials/AlignmentSelector'
import { FormFieldset } from '../../partials/FormFieldset'

import { headingContentFactory } from '../Heading.factory'
import {
  HeadingModule,
  HeadingModuleContent,
  HeadingModuleContentInput,
  HeadingOptions
} from '../Heading.contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<HeadingModuleForm>({
  name: 'HeadingModuleForm',
  components: {
    ColorSchemaSelector,
    FormFieldset,
    HAlignmentSelector
  }
})
export class HeadingModuleForm extends AbstractModuleForm<HeadingModuleContentInput, HeadingModule> {
  public initialContent: HeadingModuleContent = headingContentFactory()
  /**
   * Determines component config.
   */
  public config: HeadingOptions = this.getComponentOptions(
    { decorators: ['default'] }
  )

  public get decoratorOptions (): DashmixSelectItem[] {
    return this.config.decorators.map((decorator: string) => {
      return {
        label: decorator,
        value: decorator
      }
    })
  }

  /**
   * Heading levels of `Heading`
   */
  public get headingOptions (): DashmixSelectItem[] {
    const options = []

    for (let i = 1; i < 7; i++) {
      options.push({
        label: `${i}`,
        value: i
      })
    }

    return options
  }

  /**
   *  Display size of `Heading`
   */
  public get headingDisplaySizeOptions (): DashmixSelectItem[] {
    const options = []

    for (const [key, value] of Object.entries(SizeMap)) {
      if (value !== SizeMap.XLarge) {
        options.push({
          label: key,
          value
        })
      }
    }

    return options
  }

  public get isZoomValid (): boolean | undefined {
    if (!this.data.zoom) {
      return undefined
    }
    return this.data.zoom > 0
  }

  public get zoomHelper (): string {
    if (this.isZoomValid === false) {
      return 'modulesForms.Heading.zoom.error'
    }
    return 'modulesForms.Heading.zoom.helpers'
  }
}

export default HeadingModuleForm
