




















































import { AnyObject } from '@movecloser/front-core'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { AllowedColor } from '../../../contexts'
import { Level as HeadingLevelMap } from '../../../dsl/atoms/Heading'
import { SizeMap } from '../../../dsl/composables'

import { ColorSchemaSelector } from '../ColorSchemaSelector'

import { HeadingData, HeadingDataProps } from './HeadingForm.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl >
 */
@Component<HeadingForm>({
  name: 'HeadingForm',
  components: { ColorSchemaSelector },
  mounted (): void {
    this.boot()
  }
})
export class HeadingForm extends Vue {
  @PropSync('content', { type: Object, required: false, default: null })
  public _content!: HeadingDataProps['_content']

  @Prop({ type: Object, required: false })
  public readonly fontColorRegistry?: AnyObject

  /**
   * Default prepared heading data.
   */
  public heading: () => HeadingData = () => ({
    color: AllowedColor.Body,
    content: '',
    level: 3,
    size: SizeMap.Medium
  })

  public toggleHeading: boolean = false
  public useCustomColor: boolean = false

  protected get hasHeading (): boolean {
    return typeof this._content.heading === 'object' && this._content.heading.content !== null && this._content.heading.content.length > 0
  }

  public get headingId (): string {
    return `toggleHeading-${new Date().getTime().toString()}`
  }

  public get headingLevelOptions (): DashmixSelectItem[] {
    return Object.entries(HeadingLevelMap).map(([name, value]) => {
      return {
        label: String(this.$t(`modulesForms._helpers.headingForm.level.options.${name}`)),
        value
      }
    }).filter(option => typeof option.value === 'number')
  }

  public get headingSizeOptions (): DashmixSelectItem[] {
    const options = []

    for (const [name, value] of Object.entries(SizeMap)) {
      if (value !== SizeMap.XLarge) {
        options.push({
          label: String(this.$t(`modulesForms._helpers.headingForm.size.options.${name}`)),
          value
        })
      }
    }

    return options
  }

  protected get isCustomColor (): boolean {
    return this._content.heading.color && this._content.heading.color.startsWith('#')
  }

  public onCustomColorToggle (value: boolean): void {
    if (value && !this.isCustomColor) {
      this._content.heading.color = '#000'
    } else if (!value && this.isCustomColor) {
      this._content.heading.color = AllowedColor.Body
    }

    this.useCustomColor = value
  }

  public onToggleHeading (value: boolean): void {
    this.toggleHeading = value
    let contentCopy = { ...this._content }

    if (this.toggleHeading) {
      contentCopy = {
        ...contentCopy,
        heading: this.heading()
      }
    } else {
      if ('heading' in contentCopy) {
        delete contentCopy.heading
      }

      this.useCustomColor = false
    }

    this._content = contentCopy
  }

  protected boot (): void {
    if (!this.hasHeading) {
      return
    }

    this.toggleHeading = true
    if (this.isCustomColor) {
      this.useCustomColor = true
    }
  }
}

export default HeadingForm
