




























































import { Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import {
  isRelated,
  ModuleMode,
  PossibleRelatedPicker,
  ProductData, ProductSneakPeakData,
  Related,
  SetDescription,
  SetType, Variant
} from '../../../contexts'

import { RelatedPartial } from '../RelatedPartial'
import { HASH_PRODUCT } from './ProductForm.config'

/**
 * Component capable to handle different types of Related sets
 */
@Component<ProductForm>({
  name: 'ProductForm',
  created () {
    this.setInitialValues()
  }
})
export class ProductForm extends RelatedPartial<SetDescription> {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isSmart!: boolean

  @PropSync('product', { type: Object, required: false, default: null })
  public _product!: Related | null

  public defaultMode: ModuleMode = ModuleMode.Default
  public mode: ModuleMode = ModuleMode.Default
  public resolvedProduct: ProductData | null = null
  public smartMode: ModuleMode = ModuleMode.Smart

  public get hasValue (): boolean {
    return this._product !== null && isRelated(this._product)
  }

  public get isDefaultMode (): boolean {
    return this.mode === this.defaultMode
  }

  public get isEmpty (): boolean {
    return this.resolvedProduct === null
  }

  public get isRelatedSet (): boolean {
    return this.isDefaultMode && this.hasValue
  }

  public get isSmartMode (): boolean {
    return this.mode === this.smartMode
  }

  public get options (): DashmixSelectItem[] {
    return [
      {
        label: `${this.$t('modulesForms._helpers.productForm.default')}`,
        value: ModuleMode.Default
      },
      {
        label: `${this.$t('modulesForms._helpers.productForm.auto')}`,
        value: ModuleMode.Smart
      }
    ]
  }

  public get variant (): Variant<string> | null {
    return this.resolvedProduct ? Object.values(this.resolvedProduct.variants)[0] : null
  }

  public get count (): number {
    return this.resolvedProduct ? Object.values(this.resolvedProduct.variants).length : 0
  }

  public clearSet (): void {
    this._product = null
    this.resolvedProduct = null
    this.$emit('clear')
  }

  public onVariantChange (mode: ModuleMode) {
    this.mode = mode

    this.clearSet()

    if (mode === ModuleMode.Smart) {
      this.setSmartProduct()
      this._product = { type: 'product', value: HASH_PRODUCT }
      // this.$nextTick(() => this.resolveRelated())
    } else if (mode === ModuleMode.Default) {
      this._product = null
    }
  }

  public selectRelatedProduct (): void {
    this.pickRelated(PossibleRelatedPicker.Product, this.setRelated, this.getRelatedSource())
  }

  public setSmartProduct () {
    this.$emit('changeProduct')
  }

  protected detectMode (): ModuleMode {
    if (!this.hasValue) {
      return ModuleMode.Default
    }

    return this._product?.value === HASH_PRODUCT ? ModuleMode.Smart : ModuleMode.Default
  }

  protected getRelatedSource (): Related | undefined {
    if (!this._product) {
      return undefined
    }
    return this._product
  }

  @Watch('resolved', { deep: true })
  protected async onResolveProduct (resolvedProduct: ProductData) {
    this.resolvedProduct = resolvedProduct
  }

  protected setInitialValues (): void {
    let candidate = this.detectMode()

    if (!this.isSmart && candidate === ModuleMode.Smart) {
      this._product = null
      candidate = ModuleMode.Default
    }

    this.mode = candidate
  }

  protected setRelated (selected: SetType): void {
    // Note! This ignore is caused by nature of computed value in Vue.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._product = selected
    this.$nextTick(() => this.resolveRelated())
  }
}

export default ProductForm
