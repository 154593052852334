









import { Component } from 'vue-property-decorator'

import Vue from 'vue'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component({
  name: 'NewsletterModuleForm'
})
export class NewsletterModuleForm extends Vue {
}

export default NewsletterModuleForm
