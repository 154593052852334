




















































































import { Component } from 'vue-property-decorator'

import { AllowedColor } from '../../../contexts'
import { SizeMap } from '../../../dsl/composables'

import { AbstractModuleForm } from '../../abstract/form'

import { LinkForm } from '../../partials/LinkForm'
import { MarkdownEditor } from '../../partials/MarkdownEditor'
import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm } from '../../partials/ImageForm'
import { Iterator } from '../../partials/Iterator'
import { HeadingForm } from '../../partials/HeadingForm'

import {
  ArtisticCarouselModule,
  ArtisticCarouselModuleContent,
  ArtisticCarouselModuleContentInput,
  SlidesInput
} from '../ArtisticCarousel.contracts'
import { artisticCarouselContentFactory } from '../ArtisticCarousel.factory'

/**
 * Form component for the `ArtisticCarousel` module.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<ArtisticCarouselModuleForm>({
  name: 'ArtisticCarouselModuleForm',
  components: {
    FormFieldset,
    HeadingForm,
    Iterator,
    ImageForm,
    LinkForm,
    MarkdownEditor
  }
})
export class ArtisticCarouselModuleForm extends AbstractModuleForm<ArtisticCarouselModuleContentInput, ArtisticCarouselModule> {
  /**
   * Determines artistic carousel initial content.
   */
  public initialContent: ArtisticCarouselModuleContent = artisticCarouselContentFactory()

  /**
   * Determines artistic carousel slides.
   */
  public get slides (): SlidesInput[] {
    return this.data.slides
  }

  /**
   * Method which return new slide to add to slides array.
   */
  public addNewSlide (): SlidesInput {
    return {
      image: null,
      description: undefined,
      heading: {
        color: AllowedColor.Body,
        content: '',
        level: 3,
        size: SizeMap.Medium
      },
      link: null
    }
  }
}

export default ArtisticCarouselModuleForm
