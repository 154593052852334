




















































































import { Component, Prop } from 'vue-property-decorator'

import { PickerCallback, PossibleRelatedPicker } from '../../../contexts'

import { AbstractModuleForm } from '../../abstract/form'
import { FormFieldset } from '../../partials/FormFieldset'
import { SetForm } from '../../partials/SetForm'
import { HeadingForm } from '../../partials/HeadingForm'

import { articlesListContentFactory } from '../ArticlesList.factory'
import {
  ArticlesListModule,
  ArticlesListModuleContent,
  ArticlesListModuleContentInput
} from '../ArticlesList.contracts'
import { FilterForm } from '../../partials/FilterForm'
import { Iterator } from '../../partials/Iterator'

/**
 * Form component for the `ArticlesList` module.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl> (original)
 */
@Component<ArticlesListModuleForm>({
  name: 'ArticlesListModuleForm',
  components: { FilterForm, FormFieldset, HeadingForm, Iterator, SetForm }
})
export class ArticlesListModuleForm extends AbstractModuleForm<ArticlesListModuleContentInput, ArticlesListModule> {
  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  /**
   * Articles list amount of article per row.
   */
  public readonly AMOUNT_OF_ARTICLE_PER_ROW: number = 3

  /**
   * Determines articles list initial content.
   */
  public initialContent: ArticlesListModuleContent = articlesListContentFactory()

  /**
   * Determines related picker.
   */
  public picker = PossibleRelatedPicker

  /**
   * Determines getter for articles per page.
   */
  public get articlesPerPage () {
    return this.data.articlesPerPage / this.AMOUNT_OF_ARTICLE_PER_ROW
  }

  /**
   * Determines setter for articles per page.
   */
  public set articlesPerPage (amount: number) {
    this.data.articlesPerPage = amount * this.AMOUNT_OF_ARTICLE_PER_ROW
  }

  public addFilter () {
    return null
  }
}

export default ArticlesListModuleForm
