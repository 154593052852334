




























import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import { FormFieldset } from '../../partials/FormFieldset'
import { HeadingForm } from '../../partials/HeadingForm'
import { ImageForm } from '../../partials/ImageForm'
import { Iterator } from '../../partials/Iterator'
import { LinkForm } from '../../partials/LinkForm/LinkForm.vue'

import { advertiseBoxContentFactory } from '../AdvertiseBox.factory'
import {
  AdvertiseBoxInput,
  AdvertiseBoxModule,
  AdvertiseBoxModuleContent,
  AdvertiseBoxModuleContentInput
} from '../AdvertiseBox.contract'
import AdvertiseBoxItem from './partials/AdvertiseBoxItem.form.vue'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<AdvertiseBoxModuleForm>({
  name: 'AdvertiseBoxModuleForm',
  components: {
    AdvertiseBoxItem,
    FormFieldset,
    HeadingForm,
    ImageForm,
    Iterator,
    LinkForm
  }
})
export class AdvertiseBoxModuleForm extends AbstractModuleForm<AdvertiseBoxModuleContentInput, AdvertiseBoxModule> {
  /**
   * Determines fixed amount of elements in `AdvertiseBoxModule`.
   */
  public readonly FIXED_AMOUNT_OF_ELEMENTS: number = 3

  /**
   * Determines `AdvertiseBoxModuleForm` initial content.
   */
  public initialContent: AdvertiseBoxModuleContent = advertiseBoxContentFactory()

  /**
   * Determines `AdvertiseBoxModuleForm` items.
   */
  public get items (): AdvertiseBoxInput[] {
    if (this.data.items.length > this.FIXED_AMOUNT_OF_ELEMENTS) {
      alert('Module is displayed correctly only when we add 3 elements')
      this.data.items = [...this.data.items].slice(0, -1)
    }

    return this.data.items
  }

  /**
   * Method which return new item to add to items array.
   */
  public addNewItem (): AdvertiseBoxInput {
    return {
      links: [],
      image: null
    }
  }
}

export default AdvertiseBoxModuleForm
