































































import { DashmixSelectItem } from '@movecloser/ui-core'
import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'
import { Theme, Variant } from '../../../dsl/atoms/Button'

import { ColorSchemaSelector } from '../../partials/ColorSchemaSelector'
import { FormFieldset } from '../../partials/FormFieldset'
import { HAlignmentSelector } from '../../partials/AlignmentSelector'
import { LinkForm } from '../../partials/LinkForm'

import { buttonContentFactory } from '../Button.factory'
import {
  ButtonModule,
  ButtonModuleContent,
  ButtonModuleContentInput, ButtonOptions
} from '../Button.contracts'

/**
 * Form component for the `Button` module.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<ButtonModuleForm>({
  name: 'ButtonModuleForm',
  components: { ColorSchemaSelector, FormFieldset, HAlignmentSelector, LinkForm }
})
export class ButtonModuleForm extends AbstractModuleForm<ButtonModuleContentInput, ButtonModule> {
  public initialContent: ButtonModuleContent = buttonContentFactory()

  /**
   * Value for the `options` prop of the `<DsSelect>` that controls the `theme` property.
   *
   * @see ButtonModuleContent.theme
   */
  public get themeOptions (): DashmixSelectItem[] {
    return Object.entries(Theme).map(([name, value]) => ({
      label: this.$t(`modulesForms._helpers.theme.options.${name}`).toString(),
      value
    })).sort((a, b) => a.label > b.label ? 1 : -1)
  }

  public config: ButtonOptions = this.getComponentOptions({ decorators: {} })

  /**
   * Value for the `options` prop of the `<DsSelect>` that controls the `variant` property.
   *
   * @see ButtonModuleContent.theme
   */
  public get variantOptions (): DashmixSelectItem[] {
    return Object.entries(Variant).map(([name, value]) => ({
      label: this.$t(`modulesForms.Button.variant.options.${name.toLowerCase()}`).toString(),
      value
    })).sort((a, b) => a.label > b.label ? 1 : -1)
  }

  public get buttonDecorators (): DashmixSelectItem[] {
    return Object.entries(this.config.decorators).map(([name, value]) => ({
      label: this.$t(`modulesForms.Button.decorator.options.${name.toLowerCase()}`).toString(),
      value: value ? `--${value}` : ''
    }))
  }
}

export default ButtonModuleForm
