









import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  ProductsTeaserSourceOptions,
  ProductTeaserSourceMode
} from '../ProductsTeaser.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ProductsTeaserSourceForm>({
  name: 'ProductsTeaserSourceForm'
})
export class ProductsTeaserSourceForm extends Vue {
  @Prop({ type: Object, required: false, default: null })
  public options!: ProductsTeaserSourceOptions

  public mode: string | null = this.options?.mode ?? null

  public sku: boolean | null = this.options?.sku ?? null

  public availableModes = [
    {
      label: this.$t('modulesForms.ProductsTeaser.options.filters').toString(),
      value: ProductTeaserSourceMode.Filters
    },
    {
      label: this.$t('modulesForms.ProductsTeaser.options.brand').toString(),
      value: ProductTeaserSourceMode.Brand
    },
    {
      label: this.$t('modulesForms.ProductsTeaser.options.category').toString(),
      value: ProductTeaserSourceMode.Category
    },
    {
      label: this.$t('modulesForms.ProductsTeaser.options.brandAndCategory').toString(),
      value: ProductTeaserSourceMode.BrandAndCategory
    },
    {
      label: this.$t('modulesForms.ProductsTeaser.options.synerise').toString(),
      value: ProductTeaserSourceMode.Synerise
    }
  ]

  @Watch('mode')
  public onModeUpdate (value: string) {
    if (value === ProductTeaserSourceMode.Filters) {
      this.$emit('update:options', { ...this.options, mode: value, sku: false })
    } else if (value === ProductTeaserSourceMode.Synerise) {
      this.$emit('update:options', { ...this.options, mode: value })
    } else {
      this.$emit('update:options', { ...this.options, mode: value, sku: true })
    }
  }
}

export default ProductsTeaserSourceForm
